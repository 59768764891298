
import { defineComponent, onBeforeMount, reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { indexApi } from '@/api/modules/index'

export default defineComponent({
  name: 'index',
  setup (props, ctx) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    onBeforeMount(() => {
      data.getList()
    })

    const data: any = reactive({
      selectedItem: 0,
      documentList: null,
      hotIssueList: null,
      encryptData: store.state.app.encryptData,
      loading: true,

      async getList () {
        const { res: documentRes } = await indexApi.handbookList({})
        console.log('操作手册列表', documentRes)
        for (const i of documentRes) {
          i.children = i.children.slice(0, 3)
        }
        data.documentList = documentRes

        const { res: hotIssueRes } = await indexApi.problemList({
          page: 1,
          paginate: 6
        })
        console.log('热门问题', hotIssueRes)
        data.hotIssueList = hotIssueRes.data

        data.loading = false
      },

      // 操作文档跳转
      goDocumentList (name: string) {
        router.push(`/document/list?dataItem=${data.encryptData}&title=${name}`)
      },
      goDocumentDetails (id: number) {
        router.push(`/document/details?dataItem=${data.encryptData}&id=${id}`)
      },

      // 热门问题跳转
      goHotIssueList () {
        router.push(`/hotIssue/list?dataItem=${data.encryptData}`)
      },
      goHotIssueDetails (id: number) {
        router.push(`/hotIssue/details?dataItem=${data.encryptData}&id=${id}`)
      }
    })

    return {
      ...toRefs(data)
    }
  }
})
